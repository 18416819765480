/*!
 * main.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */

$(window).ready(function() {

    //Navigation
    var mobileNavigationComponent = new MobileNavigation($("#mobile-navigation"), applicationProperties);
    var navigationComponent = new Navigation($("#navigation"), applicationProperties);


    //Search Component
    var searchComponent = new Search($("#search"), applicationProperties);

    //Language Selector
    var languageSelector = new LanguageSelector($("#language-selector"));

    //footer
    //   var footer = new Footer($("footer"));

    //Page animations
    $("body").show();
    $("header").delay(300).animate({ top: 0 }, 100, function() {
        $("#page-loader").delay(450).fadeOut();
    });

});

/*
* featured-story.js
* Created by Santiago Prada
* Copyright © 2016 IBM. All rights reserved.
*/


$(document).ready(function() {

//Delay action by a few miliseconds to guarrantee the element in present in the dom
setTimeout(function() {

    let articleColumn = $("#article-column");
    let expandableMediaItems = $(".expandable");
    let allMediaItems = $(".media-item");
    let mediaColumn = $("#media-column");
    let closeButtons = $(".close-expandable-button");


    // Util Functions
    function getWidth() {
        return $(".main-article-container").width();
    }

    function getOffset(item) {
        return $(item).offset().top;
    }

    function getTranslateValue() {
        if (window.innerWidth < 1351) {
            return "translate(40px, 0)"
        } else {
            return "translate(0, 0)"
        }

    }

    //Animations
    // This is used for closing one or more items, closeColumn determines if closing the left column is needed or should stay hiddden
    // due to another item animating to open
    function closeActiveItem(item, closeColumn) {

        item.removeClass("expandable-active");

        item.css({
            width: ""
        });

        setTimeout(function() {
            item.css({
                transform: "translate(0,0)",
            });
            item.addClass("expandable");

            if (closeColumn) {
                articleColumn.css({
                    transform: getTranslateValue(),
                });
            }
            articleColumn.css({
                height: ""
            });
        }, 400);

    }


    $.each(closeButtons, function(index, button) {
        $(button).click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).css({
                "visibility": "hidden"
            });
            closeActiveItem($(expandableMediaItems[index]), true)
        });
    });

    $.each(expandableMediaItems, function(index, item) {

        $(item).click(function(e) {
            if (window.innerWidth < 768) {
                return
            }

            //Find active item first
            if (!$(item).hasClass("expandable-active")) {
                closeActiveItem($(".expandable-active"), false);
            }

            $(item).addClass("expandable-active");

            //Remove Class to remove interaction while open
            $(item).removeClass("expandable")

            $(item).css({
                transform: "translate(-" + articleColumn.width() + "px,0)"
            });

            setTimeout(function() {
                $(item).css({
                    width: getWidth(),
                });

                $(".close-expandable-button", item).css({
                    "visibility": "visible"
                });

            }, 400);

            // 1 hide article
            articleColumn.css({
                transform: "translate(-" + articleColumn.width() + "px,0)",
            });

            articleColumn.animate({
                height: 20
            }, 600);
        });
    });

    $(window).on("resize", function() {
        $(".expandable-active").trigger("click");
        if (window.innerWidth < 768) {
            if ($(".expandable-active").length > 0) {
                location.reload();
            };
        };
    });
});
});

(function() {

    var mediaQueries = [
        // WARNING: changes here must be reflected to media queries mixins on scss
        { name: 'media-x-large', condition: 'max-width: 1599px' },
        { name: 'media-large', condition: 'max-width: 1199px' },
        { name: 'media-medium', condition: 'max-width: 1023px' },
        { name: 'media-small', condition: 'max-width:  767px' },
        { name: 'media-x-small', condition: 'max-width:  479px' }
    ];

    for (var index in mediaQueries) {
        var mq = mediaQueries[index];

        var noTransition = false;
        enquire.register('screen and (' + mq.condition + ')', {
            match: function() {
                updateBodyClassForMediaQuery(mq, true, noTransition);
                noTransition = true;
            },
            unmatch: function() {
                updateBodyClassForMediaQuery(mq, false, noTransition);
                noTransition = true;
            }
        });
    }

    function updateBodyClassForMediaQuery(mediaQuery, matches, noTransition) {
        var body = $('body');

        if (noTransition)
            body.addClass('no-transition');

        if (matches)
            body.addClass(mediaQuery.name);
        else
            body.removeClass(mediaQuery.name);

        if (noTransition)
            setTimeout(function() {
                body.removeClass('no-transition');
            }, 100);
    }

})();